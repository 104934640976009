<template>
  <v-select
    v-model="selected"
    :items="colunas"
    outlined
    item-text="descricao"
    item-value="valor"
    dense
    hide-details
    label="Campo Data"
    return-object
  >
  </v-select>
</template>

<script>
export default {
  name: "SelectedInicioEntrega",

  data() {
    return {
      selected: {
        descricao: "Data Inicio",
        valor: "inicio",
      },
      colunas: [
        {
          descricao: "Data Inicio",
          valor: "inicio",
        },
        {
          descricao: "Data Entrega",
          valor: "entrega",
        },
      ],
    };
  },

  watch: {
    selected() {
      if (this.selected) {
        this.$store.commit("Filtros/UPDATE_DATA_COLUNA", this.selected.valor);
      }
    },
  },

  mounted() {
    if (this.$route.query.dataColuna) {
      let coluna = this.colunas.find(
        (item) => item.valor == this.$route.query.dataColuna
      );
      this.selected = coluna;
    }
  },
};
</script>

<style>
</style>